.App {
  text-align: center;
}

.MuiFormControl-marginDense {
  width: 100%;
}

.ra-input-pictures .RaFileInput-dropZone {
  display: flex;
  min-height: 300px;
  align-items: center;
  justify-content: center;
}

.ra-input-pictures p.MuiTypography-root {
  display: none;
}

.ra-input-pictures .previews .RaFileInput-removeButton {
  width: 100%;
}
.ra-input-pictures .previews .RaFileInput-removeButton .MuiBox-root{
  height: 400px;
}

.ra-input-pictures .previews .RaFileInput-removeButton .MuiBox-root img {
  margin: 0;
  width: 100%;
  height: 100%;
  object-fit: unset;
}
